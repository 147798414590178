import React, { Component, useEffect, useState } from "react";
import { ProductsContextProvider } from "./Global/ProductsContext";
import { Home } from "./Components/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Signup } from "./Components/Signup";
import { Login } from "./Components/Login";
import { NotFound } from "./Components/NotFound";
import { auth, db } from "./Config/Config";
import { CartContextProvider } from "./Global/CartContext";
import { Cart } from "./Components/Cart";
import { AddProducts } from "./Components/AddProducts";
import { Cashout } from "./Components/Cashout";
import { Landing } from "./Components/Landing";
// import StripeContainer from "./Components/StripeContainer";
import Payment from "./Components/PaymentForm";
import Success from "./Components/Success";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsofService from "./Components/TermsofService";
import Support from "./Components/ContactUs";
import RefundPolicy from "./Components/RefundPolicy";
import ShippingPolicy from "./Components/ShippingPolicy";
import AdminDashboard from "./Components/Dashboard";
import Profile from "./Components/Profile";

import { useTranslation } from 'react-i18next';

import blueLogoEnglish from "./images/blue-logo-1.png";
import blueLogoArabic from "./images/blue-logo-2.png";
import whiteLogoEnglish from "./images/white-logo-1.png";
import whiteLogoArabic from "./images/white-logo-2.png";

const App = () => {
  const { i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [language, setLanguage] = useState("عربي");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("SignedUpUsersData")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            const userData = snapshot.data();
            if (userData) {
              setUser(userData.Name);
            }
          });
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleLanguage = () => {
    if (language === "en") {
      setLanguage("عربي");
      i18n.changeLanguage("en");
    } else {
      setLanguage("en");
      i18n.changeLanguage("ar");
    }
  };
  return (
    <ProductsContextProvider>
      <CartContextProvider>
        <BrowserRouter>
          <Switch>
            {/* landing page */}
            <Route
              exact
              path="/"
              component={() => <Landing user={user} data={{ language, handleLanguage, blueLogoArabic, blueLogoEnglish, whiteLogoArabic, whiteLogoEnglish }} />}
            />

            {/* products */}
            <Route
              path="/products"
              component={() => <Home user={user} data={{ language, handleLanguage, blueLogoArabic, blueLogoEnglish, whiteLogoArabic, whiteLogoEnglish }} />}
            />
            {/* signup */}
            <Route path="/signup" component={Signup} />
            {/* login */}
            <Route path="/login" component={Login} />
            {/* cart products */}
            <Route
              path="/wishlist"
              component={() => <Cart user={user} />}
            />
            {/* <Route
                path="/payment"
                component={() => <StripeContainer user={user} />}
              /> */}
            <Route path="/payment" component={() => <Payment />} />

            {/* add products */}
            <Route path="/addproducts" component={AddProducts} />
            {/* cashout */}
            {/* <Route
                path="/cashout"
                component={() => <Cashout user={user} />}
              /> */}
            <Route
              path="/privacy-policy"
              component={() => <PrivacyPolicy />}
            />
            <Route
              path="/terms-of-service"
              component={() => <TermsofService />}
            />
            <Route path="/contact-us" component={() => <Support />} />
            <Route
              path="/refund-policy"
              component={() => <RefundPolicy />}
            />
            <Route
              path="/shipping-policy"
              component={() => <ShippingPolicy />}
            />
            <Route path="/profile" component={() => <Profile />} />
            <Route
              path="/success/:paymentId"
              component={() => <Success />}
            />
            {/* <Route
                path="/success"
                component={() => <Success />} 
              /> */}
            <Route path="/dashboard" component={() => <AdminDashboard />} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </CartContextProvider>
    </ProductsContextProvider>
  );
}

export default App;