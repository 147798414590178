import React from "react";
import Logo from "../images/blue-logo-1.png";

function TermsofService() {
  return (
    <div className="container w-full flex items-center justify-center">
      <div className="wrapper pb-20 md:py-10 w-full flex flex-col items-center justify-center md:px-40">
        <div className="flex items-center justify-center w-full gap-5 py-10">
          <div className="w-60 md:w-80">
            <img src={Logo} alt="logo" className="w-full" />
          </div>
        </div>
        <hr className="w-1/2" />

        <div className="flex flex-col gap-4 items-center justify-center w-full py-10">
          <div className="text-2xl md:text-4xl font-bold">
            Terms & Conditions
          </div>
          <div className="text-base font-light text-gray-500">
            Last updated on March 25, 2024
          </div>
        </div>

        <div className="flex flex-col gap-10 items-center justify-center px-10">
          <div className="font-light text-sm md:text-base leading-relaxed text-justify">
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                By accessing or using Sayaara, you agree to comply with these Terms and Conditions. If you do not agree with these terms, please refrain from using our services.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Acceptance of Terms:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                By accessing or using Sayaara, you agree to be bound by these Terms and Conditions and any additional terms and policies referenced herein.These Terms constitute a legally binding agreement between you and Sayaara.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Use of Services:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>

                You must be at least 18 years old to use Sayaara.By using our services, you represent and warrant that you are of legal age to form a binding contract with Sayaara.
                You agree to use Sayaara solely for lawful purposes and in accordance with these Terms and Conditions.You may not use Sayaara in any manner that could damage, disable, overburden, or impair our website or interfere with any other party's use of Sayaara.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Intellectual Property Rights:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Sayaara and its content, including but not limited to text, graphics, logos, images, videos, and software, are protected by copyright, trademark, and other intellectual property laws.You may not modify, reproduce, distribute, or create derivative works based on Sayaara's content without prior written consent from Sayaara.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              User Accounts:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                You may be required to create an account to access certain features of Sayaara.When creating an account, you agree to provide accurate, current, and complete information.You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Privacy Policy:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Your use of Sayaara is subject to our Privacy Policy, which governs the collection, use, and disclosure of your personal information.By using Sayaara, you consent to the terms of our Privacy Policy.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Third - Party Links:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Sayaara may contain links to third - party websites or services that are not owned or controlled by Sayaara.We are not responsible for the content, privacy practices, or terms of use of these third - party sites.Your interactions with these sites are governed by their respective terms and policies.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Disclaimer of Warranties:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Sayaara is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied.Sayaara makes no representations or warranties regarding the accuracy, reliability, availability, or completeness of the information provided on Sayaara.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Limitation of Liability:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                In no event shall Sayaara, its affiliates, directors, officers, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of Sayaara, whether based on warranty, contract, tort(including negligence), or any other legal theory.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Indemnification:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                You agree to indemnify and hold harmless Sayaara, its affiliates, directors, officers, employees, agents, and licensors from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of Sayaara or any violation of these Terms and Conditions.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Governing Law and Jurisdiction:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of the United Arab Emirates(UAE), without regard to its conflict of law provisions.Any dispute arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of the UAE.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Changes to Terms and Conditions:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Sayaara reserves the right to modify or update these Terms and Conditions at any time without prior notice.Any changes will be effective immediately upon posting on this page.Your continued use of Sayaara following the posting of revised Terms and Conditions constitutes your acceptance of such changes.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>
              Contact Us:
            </strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                If you have any questions, concerns, or feedback regarding these Terms and Conditions, please contact us at <b><a href="mailto:contact@sayaara.co">contact@sayaara.co</a></b>.
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                By accessing or using Sayaara, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default TermsofService;