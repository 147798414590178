import React from "react";
import Logo from "../images/blue-logo-1.png";

function PrivacyPolicy() {
  return (
    <div className="container w-full flex items-center justify-center">
      <div className="wrapper pb-20 md:py-10 w-full flex flex-col items-center justify-center md:px-40">
        <div className="flex items-center justify-center w-full gap-5 py-10">
          <div className="w-60 md:w-80">
            <img src={Logo} alt="logo" className="w-full" />
          </div>
        </div>
        <hr className="w-1/2" />

        <div className="flex flex-col gap-4 items-center justify-center w-full py-10">
          <div className="text-2xl md:text-4xl font-bold">Privacy Policy</div>
          <div className="text-base font-light text-gray-500">
            Last updated on March 25, 2024
          </div>
        </div>

        <div className="flex flex-col gap-10 items-center justify-center px-10 text-justify">
          <div className="font-light text-sm md:text-base leading-relaxed">
            At Sayaara, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our website.
          </div>
          <div className="font-light text-sm md:text-base leading-relaxed">
            By accessing or using Sayaara, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please refrain from using our services.
          </div>

          <div className="w-full">
            <strong>Information We Collect:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                <strong className="font-bold">Personal Information:</strong> When you use Sayaara, we may collect certain personal information from you, such as your Internet Protocol (IP) address. Additionally, if you submit a contact form regarding a specific vehicle, we may collect your name, email address, phone number, physical address, and message to the seller.
              </div>
              <div>
                <strong className="font-bold">Log Files:</strong> Like many other websites, we utilize log files that may include information such as your IP address, browser type, date/time stamp, referring pages, and pages viewed. This information helps us administer the site and analyze user interactions.
              </div>
              <div>
                <strong className="font-bold">Cookies:</strong> Sayaara uses cookies to enhance user experience, record user-specific information, and customize webpage content. You can manage cookie preferences through your browser settings.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>How We Use Your Information:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                We use the information collected to provide and improve our services, personalize user experience, facilitate communication between buyers and sellers, and analyze user trends and preferences.
              </div>

              <div>
                Your personal information may also be shared with our partners, such as vehicle listing providers, to enable the display of listings and facilitate contact with sellers.
              </div>
            </div>
          </div>


          <div className="w-full">
            <strong>Disclosure of Information:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Sayaara may disclose your personal information to third-party partners and service providers as necessary to provide our services and fulfill your requests. However, we do not sell your personal information to third parties for monetary gain.
              </div>

              <div>
                Please note that when you click on links to third-party websites, your interactions with those sites are governed by their respective privacy policies. Sayaara is not responsible for the privacy practices or content of these third-party sites.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>Data Security:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                We employ reasonable security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>Children&apos;s Privacy:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                Sayaara is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will promptly delete such information from our records.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>Changes to Privacy Policy:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically for updates.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>Contact Us:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>
                If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <b><a href="mailto:contact@sayaara.co">contact@sayaara.co</a></b>.
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>Governing Law:</strong>
            <div className="font-light text-sm md:text-base leading-relaxed text-justify mt-2 flex flex-col items-start justify-center gap-2">
              <div>

                This Privacy Policy shall be governed by and construed in accordance with the laws of the United Arab Emirates (UAE), where Sayaara is registered.
                By continuing to use Sayaara, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your information as described herein.
              </div>
            </div>
          </div>


        </div>
      </div>
    </div >
  );
}

export default PrivacyPolicy;
