import React from 'react'
import { Navbar } from './Navbar';
import { Products } from './Products'

export const Home = ({ user, data }) => {
    return (
        <div className='wrapper'>
            <Navbar user={user} data={data} />
            <Products />
        </div>
    )
}
